import axios from "axios";
import { Controller } from "@hotwired/stimulus";
import { processingModal } from "src/nextgen/helpers/processing_modal";
import OrderTab from "src/nextgen/orders/Tab";
import NeoModal from "src/NeoModal";

export default class extends Controller {
  connect() {
    this.disabled = false;
    this.url = this.element.dataset.url;
  }

  async perform(e) {
    e.preventDefault();
    this.disableButton();

    try {
      const lockResponse = await this.getLock();

      if (lockResponse.status === 204) {
        try {
          await axios.post(this.url).then((response) => {
            NeoModal.add(response.data);
          });

          this.orderTab.reload('current_bill', () => {
            processingModal.hide();
          });

        } catch (error) {
          this.enableButton();

          if (error.response?.status === 422) {
            this.orderTab.reload('next_bill', () => {});

            Helper.flash_message('error', error.response.data.errors)
          } else {
            Helper.flash_message('error', 'Something went wrong')
          }
        }
      } else {
        NeoModal.add(lockResponse.data, { callback: this.enableButton });
      }
    } catch (error) {
      this.enableButton();
    }
  }

  getLock() {
    return axios.get(this.url);
  }

  disableButton() {
    this.disabled = true;
    this.element.disabled = true;
    this.element.setAttribute('disabled', 'disabled');
    processingModal.show();
  }

  enableButton() {
    setTimeout(() => {
      this.disabled = false;
      this.element.disabled = false;
      this.element.removeAttribute('disabled');
      processingModal.hide();
    }, 200);
  }

  get orderTab() {
    return new OrderTab();
  }
}
