import { Controller } from "@hotwired/stimulus";
import axios from "axios";

class MiniMessengerController extends Controller {
  static targets = ['form'];

  connect() {
    this.findOrCreateModal();
    this.modal = document.getElementById('messenger_modal');
  }

  open_modal(e) {
    e.preventDefault();

    const target = e.target;

    if (target.closest('li').classList.contains('disabled')) return;
    const link = e.target.getAttribute('href')

    axios.get(link).then((response) => {
      const data = response.data;

      const modalContent = this.modal.querySelector('.modal-content');
      if (modalContent) {
        modalContent.innerHTML = '';  // Clear the content
        modalContent.insertAdjacentHTML('beforeend', data);  // Append new data
      }

      const modalDialog = this.modal.querySelector('.modal-dialog');
      if (modalDialog) {
        modalDialog.style.width = '';
      }

      const form = this.modal.querySelector('form');

      if (form) {
        const modalWidth = form.dataset.modalWidth;
        if (modalWidth !== undefined) {
          const modalDialog = this.modal.querySelector('.modal-dialog');
          if (modalDialog) {
            modalDialog.style.width = modalWidth;
          }
        }
      }

      $(this.modal).modal('show');
    });
  };

  findOrCreateModal() {
    const modalHTML = `
      <div id="messenger_modal" class="modal inmodal fade" aria-hidden="true" role="dialog" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
          </div>
       </div>
      </div>
    `;

    if (!document.getElementById("messenger_modal")) {
      document.getElementById('neo_modals').insertAdjacentHTML("beforeend", modalHTML);
    }
  }

  removeErrors() {
    this.formTarget.querySelectorAll('span.text-danger').forEach((element) => element.remove());
  }

  addErrors(errors) {
    this.removeErrors();

    Object.entries(errors).forEach(([key, value]) => {
      const span = document.createElement('span');
      span.className = 'text-danger';
      span.textContent = value.join(', ');

      const element = this.formTarget.querySelector('#conversation_' + key);
      if (element) {
        const formGroup = element.closest('.form-group');
        if (formGroup) formGroup.appendChild(span);
      }
    });
  }

  loadMessages(url, callback = null) {
    axios.get(url).then((response) => {

      const messengerMessages = this.element.querySelector('.messenger-messages');
      if (messengerMessages) {
        messengerMessages.outerHTML = response.data;
      }

      if (typeof callback === 'function') {
        callback();
      }

      document.querySelectorAll('img[alt="ViewBooking"]').forEach(img => {
        img.style.display = 'none';
      });
    });
  }

  send_message(e) {
    e.preventDefault();
    this.removeErrors();

    const btn = e.target;
    const form = this.formTarget;

    if (!btn.hasAttribute('disabled')) {
      btn.setAttribute('disabled', 'true');

      this.formTarget.querySelectorAll("input[type=text], input[type=file], textarea").forEach((element) => {
        element.readOnly = true;
      });

      const formData = new FormData(this.formTarget);

      axios
        .post(form.getAttribute('action'), formData)
        .then(response => {
          this.formTarget.querySelectorAll("input[type=text], input[type=file], textarea").forEach((element) => {
            element.value = "";
          });

          const signature = this.formTarget.dataset.signature || '';
          const url = this.formTarget.dataset.messagesUrl;
          const conversationMessage = this.formTarget.querySelector('#conversation_message');

          if (conversationMessage) conversationMessage.value = signature;

          this.loadMessages(url, () => btn.removeAttribute('disabled'))
        })
        .catch((error) => {
          console.log(error);
          this.addErrors(error.response.data.errors);
          btn.removeAttribute('disabled');
        })
        .finally(() => {
          this.formTarget.querySelectorAll("input[type=text], input[type=file], textarea").forEach((element) => {
            element.readOnly = false;
          });
        })
    }
  }

  change_page(e) {
    e.preventDefault();
    this.loadMessages(e.target.getAttribute('href') + '&body=true')
  }
}

export default MiniMessengerController;

