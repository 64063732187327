import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import Neo from "src/Neo";

class VenueGroupController extends Controller {
  connect() {
    this.id = this.element.dataset.id;
  }

  delete(e) {
    e.preventDefault();

    const { dataset } = e.target;

    Neo.confirm(dataset.message, () => {
      axios.delete(dataset.url).then((response) => {
        Helper.flash_message('success', response.data.notice)
        Neo.reloadTable('admin_venue_groups_table');
      }).catch((error) => {
        Neo.processError(error, () => Helper.flash_message('error', error.response.data.error))
      })
    })
  }
}

export default VenueGroupController;
