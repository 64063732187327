import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["partnerCheckbox", "editPartnerBlock"];

  connect() {
    this.togglePartnerBlock();
  }

  update_is_partner() {
    this.togglePartnerBlock();
  }

  togglePartnerBlock() {
    const checkbox = this.partnerCheckboxTarget;
    const block = this.editPartnerBlockTarget;
    const classList = block.classList;

    if (checkbox.checked) {
      classList.remove('soft-hide');
    } else {
      classList.add('soft-hide');
    }
  }
}