import SmartyForm from "../src/nextgen/helpers/smarty_form";
import axios from "axios";
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.modal = $('#admin_orders_modal');
  }

  show_modal(e) {
    e.preventDefault();
    this.showVenueNoteModal(e.target.getAttribute('href'));
  }

  showVenueNoteModal(link) {
    axios.get(link).then((response) => {
      this.modal.find('.modal-content').empty().append(response.data);
      this.modal.modal('show');
    }).catch(() => {
      Helper.flash_message('error', 'Something went wrong');
    })
  };

  saveVenueNote(e) {
    e.preventDefault();

    const { rowClass } = e.target.dataset;
    const form = e.target.closest('form');
    const formData = new FormData(form);
    const action = form.getAttribute('action');
    const infoPath = form.dataset.infoPath || (action + '/info');

    axios.post(action, formData).then(() => {
      this.modal.find('.modal-content').empty();
      this.modal.modal('hide');

      axios.get(infoPath).then((response) => {
        console.log(rowClass)
        const row = document.getElementsByClassName(rowClass)[0];
        if (!row) return;

        if (row.dataset.skipInfoRow) {
          const tempContainer = document.createElement('table');
          tempContainer.innerHTML = response.data;
          const newText = tempContainer.querySelector('td.note-text').innerHTML;
          row.querySelector('.notes').innerHTML = newText;
        } else {
          const infoRow = document.getElementsByClassName(rowClass + '-note')[0];
          if (infoRow) infoRow.remove();

          if (response.status !== 204) {
            if (row) {
              const container = row.parentElement;
              const tempContainer = document.createElement('table');
              tempContainer.innerHTML = response.data;

              const newElement = tempContainer.querySelector('tr');
              const newText = tempContainer.querySelector('td.note-text').innerHTML;
              container.insertBefore(newElement, row.nextSibling);
              row.querySelector('td.notes').innerHTML = newText;
              row.classList.add('with_note');
            }
          } else {
            if (row) row.classList.remove('with_note');
          }
        }
      }).catch((e) => {
        console.error(e);
        Helper.flash_message('error', 'Something went wrong')
      })
    }).catch((e) => {
      console.error(e);
      Helper.flash_message('error', 'Something went wrong');
    })
  };
}
