import SmartyForm from '../../helpers/smarty_form';
import axios from 'axios';

class VenueAdminInvitation {
  constructor() { }

  start() {
    $(document).on('click', '#create-venue-admin', (e) => {
      e.preventDefault();

      const target = e.target;
      const url = target.getAttribute('href')

      axios.get(url, { headers: { "Accept": "application/vnd.schoolhire.modal"} } ).then(response => {
        const responseDiv = document.createElement('div');
        responseDiv.innerHTML = response.data;
        document.body.appendChild(responseDiv);

        const modal = responseDiv.querySelector('div.modal');
        const form = modal.querySelector('div.modal-body form');

        form.addEventListener('submit', (e) => {
          e.preventDefault();

          new SmartyForm().process({
            form: $(form),
            prefix: 'venue_admin',
            onSuccess: () => {
              $(modal).modal('hide');
              Neo.reloadTable('admin_users_table');
            },
            onError: () => {
            }
          });
        })

        $(modal).on('hidden.bs.modal', () => { document.body.removeChild(responseDiv) });
        $(modal).modal('show');
      })
    })
  }
}

export default VenueAdminInvitation;