import { Controller } from "@hotwired/stimulus"
import axios from 'axios';
import Neo from "src/Neo";

class VenueGroupsController extends Controller {
  static targets = ['modalForm', 'modalContent', 'venuesCheckboxes'];

  showFormModal(event) {
    event.preventDefault();

    const modal = this.modalFormTarget;
    const modalContent = this.modalContentTarget;

    if (!modalContent) return false

    axios
      .get(event.target.href)
      .then((response) => {
        modalContent.innerHTML = response.data;

        setTimeout(() => {
          $(modal).modal('show');
        }, 200);
      });
  }

  submit(event) {
    event.preventDefault();

    const modal = this.modalFormTarget;
    const form = event.target;
    const selectedIncomeType = form.querySelector('#venue_group_venue_group_income_option_attributes_income_type');

    if (['annual_fee', 'projected_income'].includes(selectedIncomeType?.value)) {
      const checkedVenues = this.venuesListNotAllocatedVenueGroup();

      if (checkedVenues.length > 0) {
        const preparedVenuesList = checkedVenues.map((el) => {
          return `${el.parentElement.textContent} - ${el.dataset.incomeType}`
        });

        const MESSAGE = "The following venues below " +
                        "have not been allocated to venue group: \n" +
                        preparedVenuesList.join('\n')

        if (!confirm(MESSAGE)) return false;
      }
    }

    const formData = new FormData(form);

    axios
      .post(form.action, formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" }
      })
      .then((response) => {
        $(modal).modal('hide');
        if (response.data.venue_group_path) {
          this.updateColumns(response.data.venue_group_path)
        } else {
          Neo.reloadTable('admin_venue_groups_table')
        }
      })
      .catch((error) => {
        console.log(error)
        this.processError(error.response, modal);
      });
  }

  updateColumns(path) {
    axios.get(path, { headers: { 'Accept': 'text/vnd.turbo-stream.html' } }).then((r) => {
      Turbo.renderStreamMessage(r.data);
    }).catch((error) => Helper.flash_message('error', 'Something went wrong'));
  }

  checkedVenues() {
    return [...this.venuesCheckboxesTargets].filter((el) => el.checked);
  }

  venuesListNotAllocatedVenueGroup() {
    return [...this.checkedVenues()].filter((el) => el.dataset.incomeType != 'Venue group');
  }

  processError(response, modal) {
    if (response.status === 422) {
      modal.querySelector('.modal-content').innerHTML = response.data;
    }
  }

  checkAllVenues() {
    this.assignVenueOptions(true);
  }

  uncheckAllVenues() {
    this.assignVenueOptions(false);
  }

  assignVenueOptions(boolean) {
    this.venuesCheckboxesTargets.forEach((el) => el.checked = boolean);
  }
}

export default VenueGroupsController;
