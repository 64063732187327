import DatatableAutoscroll from "../../helpers/datatable_autoscroll";

(function() {
  $(function() {
    var $admin_customers_modal, orderDateFormat, reloadData, showModal;

    if ($('body.admin_venue_customers_index').length < 1) {
      return;
    }

    orderDateFormat = 'D, dd M yyyy';

    $("input[name='filter[start_date]'], input[name='filter[end_date]']").datepicker({
      format: orderDateFormat,
      autoclose: true,
      endDate: '+0d'
    });

    $admin_customers_modal = $('#admin_customers_modal');

    showModal = function(link) {
      $.get(link, function(data) {
        $admin_customers_modal.find('.modal-content').empty().append(data);
        $admin_customers_modal.modal('show');
      });
    };

    $(document).on('click', '.dropdown-menu li a.open_customer_modal', function(e) {
      e.preventDefault();
      if ($(this).parent('li').hasClass('disabled')) {
        return;
      }
      return showModal($(this).attr('href'));
    });

    $(document).on('click', '.dropdown-menu li a.open_customer_venue_linkings_modal', function(e) {
      e.preventDefault();
      return showModal($(this).attr('href'));
    });

    $(document).on('click', '#admin_customers_modal form [type=submit]', function(e) {
      var $form, url;
      e.preventDefault();
      $form = $(this).closest('form');
      url = $form.attr('action');
      return $.ajax({
        type: "POST",
        url: url,
        data: $form.serialize(),
        success: function(data) {
          var reload;
          reload = $admin_customers_modal.find('form').data('reload');
          $admin_customers_modal.modal('hide');
          if (reload) {
            if ($('#customers-table').length > 0) {
              return reloadData(false);
            } else {
              return location.reload();
            }
          }
        },
        error: function(data) {
          return $admin_customers_modal.find('.modal-content').empty().append(data.responseText);
        }
      });
    });
  });

}).call(this);
