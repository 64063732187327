import { Controller } from "@hotwired/stimulus";
import axios from "axios";

class MembersDownloadController extends Controller {
  connect() {
    this.subscription = null;
  }

  setDisabled(disabled) {
    if (disabled) {
      $('.export-btn').attr('disabled', 'disabled');
    } else {
      $('.generation').removeClass('generation');
      $('.export-btn').attr('disabled', null);
    }
  }

  download(e) {
    e.preventDefault();

    const name = e.target.dataset.name;

    const availableList = ['member_details', 'member_visits', 'membership_histories'];
    if (availableList.indexOf(name) === -1) return;

    const filter = $("form#new_filter").serialize();

    if ($(e.target).prop('disabled')) return;

    const btn = $(e.target);
    btn.addClass('generation');
    this.setDisabled(true);

    axios
      .post(`/admin/members/csv.json?report_type=${name}&` + filter)
      .then(response => this.startWait(response.data.id))
      .catch(() => this.setDisabled(false));
  }

  handleReceived(message) {
    switch (message.status) {
      case 'succeeded':
        this.setDisabled(false);
        window.location.href = `/admin/reports/downloads/${message.id}`;
        break;
      case 'failed':
        this.setDisabled(false);
        Helper.flash_message('error', "Something went wrong!");
        break;
      case 'pending':
        console.log('The task added to queue. [status: pending]');
        break;
      case 'in_progress':
        console.log('The task added to queue. [status: in_progress]');
        break;
      default:
        console.log(`Unknown status: ${message.status}`);
        break;
    }
  };

  startWait(id) {
    this.subscription = App.cable.subscriptions.create(
      { channel: "ReportFileGeneratorChannel", id: id },
      { received: (message) => this.handleReceived(message) }
    );
  }
}

export default MembersDownloadController;
